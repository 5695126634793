import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'components/common'
import dev from 'assets/illustrations/skills.svg'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

export const Skills = () => (
	<Wrapper id="about">
		<SkillsWrapper as={Container}>
			<Thumbnail>
				<img src={dev} alt="" />
			</Thumbnail>
			<Details>
				<p>
					I'm extremely obsessed with finding the most elegant solution to
					problems.
				</p>
				<Button as={AnchorLink} href="#contact">
					Contact me
				</Button>
			</Details>
		</SkillsWrapper>
	</Wrapper>
)
